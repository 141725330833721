<template>
	<v-card hover height="8em" width="20em" max-width="90vw" tile @click="dialog = true">
		<v-card-text class="fill-height">
			<v-layout fill-height align-center justify-center column>
				<v-flex v-t="'quickfolders.drop_off_others'" shrink headline ma-0 text-xs-center />
			</v-layout>
		</v-card-text>
		<w-dialog v-model="dialog" max-width="50vw" :title="$t('quickfolders.drag_and_drop')">
			<v-layout column fill-height>
				<v-flex v-t="'quickfolders.info'" shrink />
				<v-flex scroll-y style="min-height: 150px">
					<FolderSelector v-model="folderId" :vendor-id-from-parent="selectedVendorId" :virtual-folders="false" :read-only-folders="false" />
				</v-flex>
				<v-flex shrink style="margin-top: 10px">
					<v-layout column>
						<DropZone v-model="files" multiple />
						<FileList v-model="files" />
					</v-layout>
				</v-flex>
			</v-layout>
			<template v-slot:actions>
				<v-layout justify-end row>
					<w-btn-save :disabled="!isValid" @click="uploadFiles" />
				</v-layout>
			</template>
		</w-dialog>
	</v-card>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'OtherFolders',
	components: {
		DropZone: () => ({
			component: import('@/components/Commons/DropZone')
		}),
		FolderSelector: () => ({
			component: import('@/components/Documents/Forms/FolderSelector')
		}),
		FileList: () => ({
			component: import('@/components/Commons/FileList')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	props: {
		callback: {
			required: false,
			type: Function,
			default: null
		},
		selectedVendorId: {
			required: true,
			type: Number
		},
		origin: {
			required: false,
			type: String,
			default: null
		}
	},
	data: function () {
		return {
			dialog: false,
			files: [],
			folderId: null
		}
	},
	computed: {
		isValid: function () {
			return !!this.folderId && this.files && this.files.length > 0
		}
	},
	methods: {
		uploadFiles: function () {
			if (this.dialogOther && !this.folderId) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('quickfolders.errors.upload_files'))
			} else {
				// TODO: migrate to DocumentManagerService
				let params = this.origin ? { origin: this.origin } : {}
				this.service.uploadItems(this.selectedVendorId, this.files, this.folderId, true, params).then(res => {
					if (this.callback) {
						this.callback(res)
					}
				})
				this.dialog = false
				this.$emit('close')
			}
		}
	}
}
</script>
